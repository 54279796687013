import React from "react";
import "./Modal.css";

const Modal = ({show, close, title, content}) => {
    return (
        <>
        {
            show ?
        <div onClick={() => close()} className="aks-modal">
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title">{title}</h4>
                </div>
                <div className="modal-body">
                    {content}
                </div>
                <div className="modal-footer">
                    <button onClick={() => close()} className="modal-exit-button">Zavřít okno</button>
                </div>
            </div>
        </div>
        : null }
        </>
        
    );
};

export default Modal;