import './App.css';
import ReactFullpage from '@fullpage/react-fullpage';
import Map from './components/Map';
import { useState } from 'react';
import Modal from './Modal';

import logo from './img/ps-logo-white.png';
import logo2 from './img/logo_white.png';
import petrImage from './img/petr.jpg';
import nikcaImage from './img/nikola.jpg';
import vitekImage from './img/vitezslav.jpg';

const location = {
  address: 'Baranova 33, Praha-Vinohrady',
  lat: 50.081618207622036,
  lng: 14.456690891481276,
}

const location2 = {
  address: 'Českobrodská 1174, Praha-Kyje',
  lat: 50.08986059471023,
  lng: 14.552584384655242,
}

const rp = {
  title: 'Realitní Právo',
  content: [
    <p>Poskytujeme klientům komplexní právní služby při realitních transakcích a zajistíme Vám kompletní servis při převodu nemovitých věcí jake je jednání s realitními kancelářemi, příprava či revize rezervačních, darovacích a kupních smluv a další.</p>,
    <p>Samozřejmostí je při těchto realitních transakcích též ověřování podpisů a advokátní úschova, stejně jako zastupování v řízení před katastrálním úřadem.</p>,
    <p>Také Vám rádi zajistíme přípravu či revizi nájemních smluv či smluv o nájmu prostor sloužících k podnikání.</p>
  ]
};

const au = {
  title: 'Advokátní Úschova',
  content: [
    <p>Každá transakce má jedinečný samostatný účet úschovy, bez jakéhokoliv přístupu třetí osoby a po dobu platnosti smlouvy o úschově bude tento účet sloužit jen a výhradně pro daný obchodní případ.</p>,
    <p>O každém pohybu na účtu úschovy informujeme bezodkladně všechny strany smlouvy emailem s výpisem z účtu úschovy a každý obchodní případ je identifikován u České advokátní komory a u banky, u které je zřízen účet úschovy</p>,
    <p>Výplata peněžních prostředků z účtu úschov je při splnění podmínek provedena bezodkladně, nejpozději však do pěti pracovních dnů.</p>
  ]
};

const op = {
  title: 'Obchodní Právo',
  content: [
    <p>Poskytujeme komplexní služby v oblasti obchodního práva, zejména se založením
    obchodních společností, převody podílů v obchodních společnostech a jejich likvidaci. Dále
    změny jednatelů, společníků a další související právní služby.</p>,
    <p>Připravíme pro Vás nejrůznější typy smluv uzavíraných při Vaší podnikatelské činnosti.</p>,
    <p>Věnujeme se také vymáhání pohledávek z obchodního styku a právu insolvenčnímu, a to od sepisu insolvenčních návrhů, přihlášek pohledávek až po zastupování v insolvenčním řízení.</p>
  ]
};

const gdpr = {
  title: 'Informace o zpracování osobních údajů',
  content: [
    <p><b>JUDr. Petr Sládek, advokát,</b> <br></br> zapsaný v seznamu advokátů vedeném Českou advokátní komorou pod číslem 17678, <br></br> IČO: 062 36 359, <br></br> se sídlem Českobrodská 1174, 198 00 Praha 9</p>,
    <p>Oprávněná osoba ohledně ochrany osobních údajů je JUDr. Petr Sládek, tel.: (+420) 234 106 333, e-mail: advokati@aksladek.com</p>,
    <p>Shromažďujeme o Vás identifikační, kontaktní a platební údaje, zejména tedy jméno a příjmení, adresa bydliště/sídla, adresa elektronické pošty, adresa datové schránky, IČO, DIČ, telefonní číslo, bankovní účet.</p>,
    <p>Vaše osobní údaje jsme získali přímo od Vás, případně z veřejně dostupných registrů a rejstříků.</p>,
    <p>Provádíme zpracování Vašich osobních údajů, neboť je to nezbytné pro splnění smlouvy s Vámi (nebo pro provedení opatření přijatých před uzavřením takové smlouvy), a dále provádíme zpracování Vašich osobních údajů, které je nezbytné pro plnění našich veřejnoprávních povinností.</p>,
    <p>Vaše osobní údaje tedy potřebujeme a zpracováváme za účelem uzavření smlouvy o poskytování právních služeb, poskytování právních služeb, jakož i pro realizaci poskytovaných služeb, řešení odpovědnosti za vady, komunikaci s Vámi a pro plnění povinností určených zákonem, zejména ve vztahu k archivaci dokumentů a účetnictví.</p>,
    <p>Vaše osobní údaje zpracováváme rovněž pro účely případného reklamačního či soudního řízení.</p>,
    <p>Právním základem pro zpracování osobních údajů je plnění smlouvy, kterou jsme s Vámi uzavřeli nebo se chystáme uzavřít.</p>,
    <p>Právním základem pro zpracování osobních údajů je plnění zákonné povinnosti, neboť zákony nám ukládají povinnost archivovat některé dokumenty, zejména daňové doklady. Tuto povinnost nám ukládá zejména zákon č. 235/2004 Sb., o dani z přidané hodnoty, zákon č. 586/1992 Sb., o daních z příjmů, a zákon č. 563/1991 Sb., o účetnictví a zákon č. 499/2004 Sb., zákon o archivnictví a spisové službě a o změně některých zákonů, zákon č. 435/2004 Sb., o zaměstnanosti. Dále nám povinnost zpracovávat Vaše osobní údaje ukládá také podle zákona č. 85/1996 Sb., o advokacii.</p>,
    <p>Dalším právním základem pro zpracování osobních údajů je náš oprávněný zájem. Osobní údaje rovněž budeme zpracovávat, a to i po skončení smluvního vztahu, pro případné soudní řízení.</p>,
    <p>Osobní údaje jsou, pro zajištění výše popsaných účelů, předávány třetím subjektům a jsou jimi zpracovávány, a to na základě smluv o zpracování osobních údajů uzavřených v souladu s nařízením a zákonem.</p>,
    <p>Zpracovatelem, jiným správcem nebo třetí osobou, kterým jsou osobní údaje předávány a jsou jimi zpracovány, jsou: spolupracující advokáti, pojišťovna v případě pojistné událost, externí účetní z důvodu zpracování účetnictví, IT technici z důvodu zajištění plynulého chodu informačních systémů, soudní znalci, v případě zpracování znaleckého posudku, tlumočníci v případě potřeby překladu, přepravce pro předání a doručení Vaší zásilky.</p>,
    <p>Příjemci Vašich osobních údajů zpracovávaných za účelem plnění povinností vyplývajících z právních předpisů mohou být dále za splnění zákonných podmínek kladených pro advokáty též orgány státní správy či České advokátní komory.</p>,
    <p>Pověřence pro ochranu osobních údajů nebyl jmenován.</p>,
    <p>Vaše osobní údaje budeme zpracovávat po dobu, po kterou budou trvat účinky práv a povinností ze smlouvy. Po skončení smluvního vztahu budeme zpracovávat Vaše osobní údaje po dobu čtyř let z titulu oprávněného zájmu, případně jeden rok po uplynutí promlčení doby, podle toho, která z těchto dob je delší.</p>,
    <p>Pokud nám zákon či stavovské předpisy ukládají povinnost archivovat Vaše osobní údaje po určitou dobu, budou tyto osobní údaje archivovány a uloženy nejdéle po dobu stanovenou v souladu s obecně závaznými právními předpisy.</p>,
    <p>Poskytnutí osobních údajů a uzavření smlouvy je dobrovolné, potřebujeme je ale pro uzavření a plnění smlouvy. Rovněž nám i zákon ukládá povinnost vzhledem k účetním dokladům zpracovávat Vaše osobní údaje. Pokud byste nám osobní údaje neposkytli, nemůžeme s Vámi uzavřít smlouvu.</p>,
    <p>Pokud nesouhlasíte se zpracováním osobních údajů námi, máte možnost uplatnit Vaše práva, o kterých Vás informujeme níže. Rovněž se kdykoli můžete obrátit se stížností na Úřad pro ochranu osobních údajů se sídlem Pplk. Sochora 27, 170 00, Praha 7, tel.: (+420) 234 665 111, web: www.uoou.cz.</p>,
    <p>Nedochází u nás k automatizovanému rozhodování. Máte právo na přístup k osobním údajům a rovněž sdělení informací, zda a případně za jakým účelem Vaše osobní údaje zpracováváme. O tomto Vám vydáme potvrzení na Vaši žádost. Máte právo na to, abychom opravili bez zbytečného odkladu nepřesné osobní údaje, které se Vás týkají. Máte právo rovněž na to, abychom vymazali Vaše osobní údaje, pokud: osobní údaje již nejsou potřebné, odvoláte svůj souhlas, pokud jste nám jej udělil, vznesete námitky proti zpracování osobní údaje zpracováváme protiprávně, osobní údaje musí být vymazány ke splnění právní povinnosti. Právem na přenositelnost údajů se rozumí veškerá Vaše práva obsažená v čl. 20 GDPR, zejména pak právo získat své osobní údaje, které nám byly poskytnuty. Rovněž máte právo nebýt předmětem žádného rozhodnutí založeného výhradně na automatizovaném zpracování. My neprovádíme automatizované rozhodování ani profilování. Kdykoli můžete obrátit se stížností na Úřad pro ochranu osobních údajů se sídlem Pplk. Sochora 27, 170 00 Praha 7, telefon: (+420) 234 665 111, web: www.uoou.cz. Máte právo kdykoliv odvolat souhlas se zpracováním osobních údajů, které jsou námi na základě vašeho souhlasu zpracovávány. Souhlas můžete odvolat na naší adrese uvedené výše.</p>
  ]
};

const adr = {
  title: 'Mimosoudní řešení sporu mezi advokátem a klientem spotřebitelem',
  content: [
    <p>Spory mezi advokáty a klienty – spotřebiteli jsou dle novely č. 378/2015 Sb., kterou se novelizuje zákon č. 634/1992 Sb., o ochraně spotřebitele, ve znění pozdějších předpisů, možné řešit mimosoudně, a to před nezávislým orgánem k řešení sporů.</p>,
    <p>Obecným orgánem k řešení těchto sporů je Česká obchodní inspekce, konkrétně Česká obchodní inspekce, ústřední inspektorát – oddělení ADR, adresa Štěpánská 15, PSČ 120 00 Praha 2, email: adr@coi.cz, web: adr.coi.cz.</p>,
    <p>Věcně příslušným orgánem mimosoudního řešení spotřebitelských sporů mezi advokátem a zákazníkem – spotřebitelem ze smluv o poskytování právních služeb je pověřena Česká advokátní komora, podrobnější informace naleznete zde.</p>,
    <p>Mimosoudní řešení spotřebitelského sporu bude zahájeno na návrh spotřebitele, který musí obsahovat tyto údaje:</p>,
    <p>identifikační údaje stran sporu;<br></br>
    úplné a srozumitelné vylíčení rozhodných skutečností;<br></br>
    označení, čeho se navrhovatel domáhá;<br></br>
    datum, kdy navrhovatel uplatnil své právo, které je předmětem sporu, u advokáta poprvé;<br></br>
    prohlášení, že ve věci nerozhodl soud, nebyl vydán rozhodčí nález a nebyla uzavřena dohoda stran v rámci mimosoudního řešení spotřebitelského sporu a ani nebylo zahájeno řízení před soudem, rozhodčí řízení nebo mimosoudní řešení spotřebitelského sporu podle zákona o ochraně spotřebitele;<br></br>
    datum a podpis navrhovatele (spotřebitele);<br></br>
    Přílohou návrhu musí být doklad o tom, že se spotřebiteli nepodařilo spor vyřešit s druhou stranou přímo, a další písemnosti dokládající tvrzené skutečnosti, jsou-li k dispozici (pokud bude navrhovatel zastoupen na základě plné moci, bude součástí návrhu také plná moc). Návrh lze podat v českém jazyce prostřednictvím přiloženého formuláře, který naleznete zde. Formulář odešlete Komoře do datové schránky, poštou, nebo e-mailem se zaručeným elektronickým podpisem.<br></br>
    </p>,
    <p>Kontaktní údaje:<br></br>
    Česká advokátní komora<br></br>
    Národní tř. 16<br></br>
    110 00 Praha 1<br></br>
    e-mail: epodatelna@cak.cz<br></br> 
    ID datové schránky: n69admd
    </p>,
    <p>Současně Vám sdělujeme, že na Vaši žádost (jako spotřebitele), Vám vydáme doklad o poskytnutí služby s uvedením data poskytnutí služby. V případě reklamace služby Vám vydáme písemné potvrzení o tom, kdy jste jako spotřebitel právo uplatnil, co je obsahem reklamace a jakým způsob vyřízení reklamace vyžadujete.</p>
  ]
};

const App = () => {
  
  const [modal, setModal] = useState(false);
  const [modalContent, setModalContent] = useState(gdpr);

  const Toggle = (data) => {
    if(data == "gdpr"){
      setModalContent(gdpr)
    } else if  (data=="adr") {
      setModalContent(adr)
    } else if (data == "rp") {
      setModalContent(rp)
    } else if (data== "au"){
      setModalContent(au)
    } else {
      setModalContent(op)
    }

    setModal(!modal);
  }
  
  return(
  <>
  
  <ReactFullpage
    licenseKey = 'XXXXXX'
    scrollingSpeed = {1000}
    navigation = {true}
    slidesNavigation = {true}
    fitToSection = {false}
    scrollBar = {false}
    paddingTop = {"50px"}
    normalScrollElements = '.aks-modal'
    keyboardScrolling={true}
    scrollOverflow={true}

    render={({state, fullpageApi}) => {
      return (       
        <ReactFullpage.Wrapper>
          
          <div className="section vertical-scrolling" id="intro">
          
          <div className="container"> 
            <div className="row justify-content-md-center">
              
              <div className="col-lg-6 col-md-7 col-sm-8 col-xs-5 p-5 m-5" style={{border: '2px solid white'}}>
              <img  id="logo" src={logo2} alt="a sand dune" className="border-2-white"></img>
              </div>
            </div>
            
            <div className="scroll-icon">
              <p>Právní služby</p>
              <a href='#intro' onClick={() => fullpageApi.moveSectionDown()} class="icon-up-open-big"></a>
            </div>
            </div>
          </div>

          <div className="section vertical-scrolling" id="services">
            
          <div className="container">
              <div className="row">
                <div className="col-md-12">
                  
                  <div className="blankspace"></div>
                  <p>Advokátní kancelář JUDr. Petra Sládka Vám může nabídnout poskytování právních služeb
zejména v oblastech obchodního práva, realitního práva včetně advokátní úschovy. Pokud
potřebujete právní radu či jinou právní pomoc, neváhejte a kontaktujte nás.</p>
                  <hr className="dashed"></hr>
                  <div className="blankspace"></div>
                  <hr className="dashed"></hr>
                  <div className="separator">
                    <span className="single-line-middle"></span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4 col-md-12 col-sm-12">
                  <div className="webcoderskull-item">
                    <h3><a href="#">Realitní právo</a></h3>
                    <p className='justify-left'>Poskytujeme klientům komplexní právní služby při realitních transakcích. Zajistíme Vám
kompletní servis při převodu nemovitých věcí. Zajistíme Vám jednání s realitními
kancelářemi, přípravu či revizi rezervačních, darovacích a kupních smluv. Samozřejmostí je
při těchto realitních transakcích též ověřování podpisů a advokátní úschova, stejně jako
zastupování v řízení před katastrálním úřadem.
Také Vám rádi zajistíme přípravu či revizi nájemních smluv či smluv o nájmu prostor
sloužících k podnikání.</p>
                   
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                  <div className="webcoderskull-item">
                    <h3><a href="#">Advokátní úschova</a></h3>
                    <p className='justify-left'>Každá transakce má jedinečný samostatný účet úschovy, bez jakéhokoliv přístupu třetí
osoby. Po dobu platnosti smlouvy o úschově bude tento účet sloužit jen a výhradně
pro daný obchodní případ. O každém pohybu na účtu úschovy informujeme bezodkladně všechny strany smlouvy
emailem s výpisem z účtu úschovy a každý obchodní případ je identifikován u České advokátní komory a u banky, u které je zřízen účet úschovy. Výplata peněžních prostředků z účtu úschov je při splnění podmínek provedena
bezodkladně, nejpozději však do pěti pracovních dnů.</p>
              
                  </div>
                </div>
                <div class="col-lg-4 col-md-12 col-sm-12">
                  <div class="webcoderskull-item">
                    <h3><a href="#">Obchodní právo</a></h3>
                    <p className='justify-left'>Poskytujeme komplexní služby v oblasti obchodního práva, zejména se založením
obchodních společností, převody podílů v obchodních společnostech a jejich likvidaci. Dále
změny jednatelů, společníků a další související právní služby.
Připravíme pro Vás nejrůznější typy smluv uzavíraných při Vaší podnikatelské činnosti.
Věnujeme se také vymáhání pohledávek z obchodního styku a právu insolvenčnímu, a to od
sepisu insolvenčních návrhů, přihlášek pohledávek až po zastupování v insolvenčním řízení.</p>
          				
                  </div>
                </div>
              </div>

              <h4><b>Odměna za poskytování právních služeb</b></h4>


                <p>Vždy se snažíme najít společné řešení, abychom Vám pomohli za rozumných finančních
podmínek a klient vždy věděl, kolik za službu bude platit.
                </p>
            </div>
          

            <div className="scroll-icon">
              <p>O nás</p>
              <a onClick={() => fullpageApi.moveSectionDown()} class="icon-up-open-big"></a>
            </div>
          </div>


          <div className="section vertical-scrolling" id="team">
  <div class="container">
    <div class="row text-center justify-content-md-center">
          <div className="col-md-4">
            <div className="team-member">
              <figure>
                <img src={[nikcaImage]} alt="" className="img-responsive"></img>
                <figcaption>
                  <p className='reveal-text'>Mgr. Nikola Hanousková působí v advokátní kanceláři od roku 2022 na pozici právníka.
Právnickou fakultu Západočeské univerzity v Plzni absolvoval v roce 2014. Následně
působila v notářských kancelářích a na katastru nemovitostí až do roku 2022. V roce 2017
složila notářskou zkoušku u Notářské komory České republiky. V rámci své právní praxe se
specializuje zejména na oblasti práva katastrálního a stavebního a práva soukromého, se
zaměřením na nemovitosti.<br></br><br></br>
Email: hanouskova@aksladek.com</p>
                </figcaption> 
              </figure>
              <h4>Mgr. Nikola Hanousková</h4>
              <p>Právník</p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="team-member">
              <figure>
                <img src={[petrImage]} alt="" className="img-responsive"></img>
                <figcaption>
                  <p className='reveal-text'>JUDr. Petr Sládek je zakladatelem advokátní kanceláře. Právnickou fakultu Západočeské
univerzity v Plzni absolvoval v roce 2014, následně v roce 2023 získal na Právnické fakultě
Univerzity Palackého v Olomouci titul JUDr. Od roku 2017 je advokátem zapsaným pod č.
17678 v seznamu advokátů vedeném Českou advokátní komorou. V rámci své specializace

poskytuje komplexní právní služby v oblasti práva obchodního, občanského, se zaměřením na
nemovitosti a závazky.</p>
                </figcaption> 
              </figure>
              <h4>JUDr. Petr Sládek</h4>
              <p>Advokát a zakladatel</p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="team-member">
              <figure>
                <img src={[vitekImage]} alt="" className="img-responsive"></img>
                <figcaption>
                  <p className='reveal-text'>JUDr. Vítězslav Semorád působí v advokátní kanceláři od roku 2023 na pozici advokátního
koncipienta. Právnickou fakultu Západočeské univerzity v Plzni absolvoval v roce 2018,
kde v roce 2019 získal také titul JUDr. V roce 2023 byl zapsán v seznamu advokátních
koncipientů vedeném Českou advokátní komorou pod č. 46248. V rámci své právní praxe se
specializuje zejména na oblasti práva občanského, trestního či vymáhání pohledávek.
<br></br><br></br>Email: semorad@aksladek.com</p>
                </figcaption>  
              </figure>
              <h4>JUDr. Vítězslav Semorád</h4>
              <p>Advokátní koncipient</p>
            </div>
          </div>
        </div>

        
  </div>
  <div className="scroll-icon">
              <p>Kontakt</p>
              <a onClick={() => fullpageApi.moveSectionDown()} class="icon-up-open-big"></a>
            </div>
</div>





          

          <div className="section vertical-scrolling" id="contact">
            <div className="container-fluid p-0">
              
              <div className="row justify-content-md-center" id="contact-row">
                <div className="col-lg-6 my-auto">
                  <div className="contact-bottom">
                      <h3><b>KONTAKT</b></h3>
                      <h4><b>JUDr. Petr Sládek, advokát</b></h4>
                      <p>zapsaný v seznamu advokátů vedeném Českou advokátní komorou pod číslem 17678,
                        <br></br>IČO: 062 36 359 DIČ: CZ8906150418</p>
                      <p><b>Sídlo:</b><br />Českobrodská 1174, 198 00, Praha 9</p>
                      <p><b>Pobočka:</b> <br /> Baranova 1026/33, 130 00, Praha 3</p>
                      <div className='contact-details'>
                      <p>e-mail: advokati@aksladek.com <br />
                      tel.: (+420) 234 106 333</p>
                      </div>
                      <span><a href='#gdpr' onClick={() => Toggle("gdpr")}><u>Informace o zpracování osobních údajů</u></a> | <a href='#adr' onClick={() => Toggle("adr")}><u>Mimosoudní řešení sporu mezi advokátem a klientem spotřebitelem</u></a></span>
                  </div> 
                </div>
              </div>
              <Map location={location} location2={location2} zoomLevel={13}/>
            </div>
            
          </div>
          
        </ReactFullpage.Wrapper>
      );
    }
    }
    />
    <Modal show={modal} title={modalContent.title} content={modalContent.content} close={Toggle}></Modal>
    </>
)};

export default App;
